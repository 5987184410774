import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 5; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/12-jan-24-1/pic${i}.jpeg`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function VisitAtSoilGurugram2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">Visit at SOIL, Gurugram</h1>

        <p>
          The students of Chinmaya Vidyalaya recently attended the India@100
          Initiatives Conference at SOIL Institute of Management.
          <br />
          <br />
          Selected students from XI and XII participated in the 15th Inspired
          Leadership Conference that aimed at spelling out Vision 2047 for
          India.
          <br />
          <br />
          The participants in the Conference included professionals, educators
          and students- all stakeholders in the nation’s progress.
          <br />
          <br />
          There is an increasing need to adopt sustainable development as the
          way forward. It can be achieved only by achieving equilibrium across
          the key focus areas of economic growth, social inclusion and
          environmental protection.
          <br />
          <br />
          Chinmaya Vidyalaya students actively participated in the discussions
          and contributed to creating India's Vision for 2047, per SDGs.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
